import React from 'react';

export default function ProfileImage({ source }) {
  return (
    <img
      src={source}
      className={'h-32 w-32 rounded-full object-cover'}
      alt={'Profile Image'}
    />
  );
}
