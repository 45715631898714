import { useEffect, useState } from 'react';
import qrCode from 'qrcode';
import getReferringBranchLink from '../helpers/getReferringBranchLink';

export default function useExtractBranchLinkAndPaintQrCode(
  qrCanvasRef: React.MutableRefObject<HTMLCanvasElement>,
  generatedBranchLink: string,
  agentObject,
): {
  branchLink: string;
  isQrCodeCreated: boolean;
} {
  const [branchLink, setBranchLink] = useState('');
  const [isQrCodeCreated, setIsQrCodeCreated] = useState(false);

  useEffect(() => {
    async function generateBranchBridgeToApp() {
      const referringBranchLink = await getReferringBranchLink();

      // Debugging purposes
      console.log('referringBranchLink');
      console.log(referringBranchLink);
      console.log('generatedBranchLink');
      console.log(generatedBranchLink);

      const branchLink = referringBranchLink || generatedBranchLink;
      if (branchLink) {
        qrCode.toCanvas(qrCanvasRef.current, branchLink, {
          width: 200,
          margin: 0,
        });
        setIsQrCodeCreated(true);
        setBranchLink(branchLink);
      }
    }
    if (agentObject) {
      generateBranchBridgeToApp();
    }
  });

  return {
    branchLink,
    isQrCodeCreated,
  };
}
