import fetchAgentObject from '../../helpers/queries/fetchAgentObject';
import { Agent, AgentObject } from '@th/shared/src/types/Agent';
import ErrorPage from 'next/error';
import React, { useRef } from 'react';
import ProfileImage from '../../components/ProfileImage';
import { Screen } from '@th/shared/src/types/Screen';
import createBranchLink from '../../helpers/mutations/createBranchLink';
import Link from 'next/link';
import useExtractBranchLinkAndPaintQrCode from '../../hooks/useExtractBranchLinkAndPaintQrCode';

type AgentPageProps = {
  agentObject: AgentObject | null;
  agentProfileBranchLink?: string;
};

export async function getServerSideProps(context) {
  const { agentSlug } = context.params;

  const agentObject = await fetchAgentObject(agentSlug);

  let agentProfileBranchLink;
  try {
    // try/catch here in case branch API is down, we should still serve the webpage (it's happened before)
    agentProfileBranchLink = await createBranchLink({
      agentSlug,
      type: Screen.AgentProfile,
      fallbackToWebIfAppNotInstalled: false,
    });
  } catch (err) {}

  return { props: { agentObject, agentProfileBranchLink } };
}

export default function AgentPage({
  agentObject,
  agentProfileBranchLink,
}: AgentPageProps) {
  const qrCanvasRef = useRef(null);

  const { branchLink, isQrCodeCreated } = useExtractBranchLinkAndPaintQrCode(
    qrCanvasRef,
    agentProfileBranchLink,
    agentObject,
  );

  if (!agentObject) {
    return <ErrorPage statusCode={404} />;
  }

  const agent = Agent.fromObject(agentObject);

  return (
    <div className={'main'}>
      {Boolean(agent.profileImageUri) && (
        <div className={'mb-8'}>
          <ProfileImage source={agent.profileImageUri} />
        </div>
      )}
      <h3>{agent.legalName}</h3>
      <div className="text-center pt-4">
        <p>{agent.brokerageName}</p>
      </div>

      {isQrCodeCreated && (
        <div className={'hidden sm:flex mt-16 flex-col items-center'}>
          <p>
            Scan this QR Code with your phone{`'`}s camera to{' '}
            <span>
              <Link href={'/m'}>browse listings</Link>
            </span>{' '}
            on my app
          </p>
          <canvas ref={qrCanvasRef} className={'mt-8'} />
        </div>
      )}

      {branchLink && (
        <div className={'flex sm:hidden pt-12'}>
          <Link
            href={branchLink}
            target="_blank"
            rel="noopener"
            className="border p-2 px-4 rounded-xl bg-primary text-white"
          >
            Browse listings in my app
          </Link>
        </div>
      )}
    </div>
  );
}
