import isBranchBlocked from './isBranchBlocked';

export default function getReferringBranchLink(): Promise<string | null> {
  return new Promise(async (resolve, _) => {
    if (!window.branch) {
      resolve(null);
      return;
    }
    if (await isBranchBlocked()) {
      resolve(null);
      return;
    }
    window.branch.data((err, data) => {
      if (err) {
        resolve(null);
        return;
      }
      resolve(data?.data_parsed['~referring_link']);
    });
  });
}
