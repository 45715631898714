export default async function isBranchBlocked(): Promise<boolean> {
  try {
    // This fetch is to check if ad-blockers are blocking branch calls
    // Which will prevent the callback in window.branch.data from being reached
    // If the fetch fails, this fetch will throw and we'll end up in the catch block
    await fetch('https://cdn.branch.io');
    return false;
  } catch (ex) {
    // Client probably has an ad-blocker enabled
    return true;
  }
}
